import EntryProxy from '@/app/proxies/EntryProxy';

export default {
    namespaced: true,
    state: {
        entry: {
        }
    },

    getters: {
        all: state => state.entry
    },

    mutations: {
        assign: ( state, payload ) =>
        {
            state.entry = Object.assign({}, state.entry, payload );
        }
    },

    actions: {
        merge: ( context, payload ) =>
        {
            context.commit( 'assign', payload );
        },

        reset( context, payload )
        {
            context.commit( 'assign', {
                score: 0
            });
        },

        save: async ( context, payload ) =>
        {
            context.commit( 'assign', payload );
            context.commit( 'assign', { score: context.rootGetters[ 'game/score' ] });

            // console.log( 'full entry', context.getters.all );

            try
            {
                const result = await new EntryProxy().create( context.getters.all );
                context.commit( 'assign', result );
                return Promise.resolve( result );
            }
            catch( e ) {}
        }
    }
};
