<template>
    <div class="bg-wrapper">
        <img class="slinger" src="/static/img/slinger.svg" />
        <img class="slinger" src="/static/img/slinger.svg" />
    </div>
</template>

<style lang="scss" scoped>
    .bg-wrapper{
        background: rgb(243,145,28);
        background: url( '/static/img/stl-bg.svg?v2' ) repeat center center, linear-gradient(90deg, rgba(243,145,28,1) 0%, rgba(247,164,40,1) 50%, rgba(243,145,28,1) 100%);
        background-size: 50vh auto, 100% auto;
    }
</style>

<script>
    export default {

    };
</script>
