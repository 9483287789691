/* eslint-disable */
import { gsap } from 'gsap';
import * as PIXI from 'pixi.js';

PIXI.utils.destroyTextureCache();
PIXI.utils.skipHello();

export {
    gsap,
    PIXI
};
