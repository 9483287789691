export default {
    namespaced: true,
    state: {
        questions: [
            {
                id: 0,
                content: 'In 1726 vond de eerste trekking van de Staatsloterij in de Ridderzaal plaats.',
                icon: '/static/img/question-icon_ridderzaal.png',
                answers: [
                    { content: 'Waar', correct: true },
                    { content: 'Niet waar', correct: false }
                ],
                results: {
                    correct: 'Toen heette de Staatsloterij nog de Generaliteitsloterij en de hoofdprijs was 30.000 gulden. Destijds een behoorlijk kapitaal.',
                    incorrect: 'De eerste trekking vond echt plaats in de Ridderzaal in Den Haag.'
                }
            },
            {
                id: 1,
                content: 'Napoleon ondertekende in 1812 het nieuwe loterij reglement van de Staatsloterij.',
                icon: '/static/img/question-icon_napoleon.png',
                answers: [
                    { content: 'Waar', correct: true },
                    { content: 'Niet waar', correct: false }
                ],
                results: {
                    correct: 'Op 13 oktober 1812 ontving de Franse Keizer Napoleon Bonaparte een afvaardiging van de Hollandse Loterij. Dit resulteerde in goedkeuring van Napoleon middels een keizerlijk decreet.',
                    incorrect: 'Op 13 oktober 1812 ontving de Franse Keizer Napoleon Bonaparte een afvaardiging van de Hollandse Loterij. Dit resulteerde in goedkeuring van Napoleon middels een keizerlijk decreet.'
                }
            },
            {
                id: 2,
                content: 'Vincent van Gogh schilderde ooit een schilderij met als onderwerp de Staatsloterij',
                icon: '/static/img/question-icon_vincent.png',
                answers: [
                    { content: 'Waar', correct: true },
                    { content: 'Niet waar', correct: false }
                ],
                results: {
                    correct: 'Het in spanning wachten voor het Staatsloterij kantoor viel de schilder van Gogh destijds op. In 1882 maakte hij hiervan een prachtig schilderij en schreef erover naar zijn broer Theo.                    ',
                    incorrect: 'Het in spanning wachten voor het Staatsloterij kantoor viel de schilder van Gogh destijds op. In 1882 maakte hij hiervan een prachtig schilderij en schreef erover naar zijn broer Theo.                    '
                }
            },
            {
                id: 3,
                content: 'De hoogste Jackpot ooit bedroeg 35 miljoen euro netto en werd gewonnen in Zwolle.',
                icon: '/static/img/question-icon_euro.png',
                answers: [
                    { content: 'Waar', correct: false },
                    { content: 'Niet waar', correct: true }
                ],
                results: {
                    correct: 'De hoogste Jackpot ooit was 38,4 miljoen euro netto en werd gewonnen in Utrecht.',
                    incorrect: 'De hoogste Jackpot ooit was 38,4 miljoen euro netto en werd gewonnen in Utrecht.'
                }
            },
            {
                id: 4,
                content: 'De Staatsloterij heeft de Allergrootste prijzenpot van Nederland',
                icon: '/static/img/question-icon_STL.png',
                answers: [
                    { content: 'Waar', correct: true },
                    { content: 'Niet waar', correct: false }
                ],
                results: {
                    correct: 'Met 405 miljoen euro netto in 2021 heeft de Staatsloterij weer de Allergrootste prijzenpot van Nederland.',
                    incorrect: 'Met 405 miljoen euro netto in 2021 heeft de Staatsloterij weer de Allergrootste prijzenpot van Nederland.'
                }
            }
        ],
        currentQuestion: -1,
        results: [],
        score: 5
    },

    getters: {
        all: state => state.questions,
        currentQuestion: state => state.currentQuestion,
        score: state => state.score,
        results: state => state.results
    },

    mutations: {
        scoreIncrement: ( state ) =>
        {
            state.score++;
        },

        questionIncrement: ( state ) =>
        {
            state.currentQuestion++;
        },

        storeResult: ( state, payload ) =>
        {
            state.results.push({ questionId: payload.questionId, correct: payload.correct });
        },

        reset: ( state ) =>
        {
            state.score = 0;
            state.results = [];
            state.currentQuestion = -1;
        },

        setQuestionId: ( state, payload ) =>
        {
            state.currentQuestion = payload.questionId;
        }

    },

    actions: {
        reset: ( context ) =>
        {
            context.commit( 'reset' );
        },

        start: ( context ) =>
        {
            context.commit( 'setQuestionId', { questionId: 0 });
        },

        answer: ( context, payload ) =>
        {
            // console.log( 'payload ', payload );

            let correct = false;
            if( context.getters['all'][payload.questionId].answers[payload.answerId].correct === true )
            {
                correct = true;
                context.commit( 'scoreIncrement' );
            }

            // maybe we should check if this result is already stored?
            context.commit( 'storeResult', {
                correct,
                questionId: payload.questionId
            });

            return correct;
        },

        nextQuestion: ( context ) =>
        {
            context.commit( 'questionIncrement' );
        }
    }
};
