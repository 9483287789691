<template>
    <div :class="{ 'main-layout': true, 'simple': true, [ ( $route.name ? $route.name.toLowerCase() : '' ) + '-route']: true }">

        <!--<img class="stl-label" src="/static/img/stl-label.png" /> -->

        <div class="content">
            <bg-elem></bg-elem>

            <svg class="golden-border" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" :viewBox="'0 0 ' + wWidth + ' ' + wHeight" preserveAspectRatio="none" >
                <defs>
                    <linearGradient id="gold-gradient" x1="0%" y1="0%" x2="0%" y2="100%" gradientUnits="userSpaceOnUse" gradientTransform="rotate(-15)">
                        <stop offset="0%" stop-color="#e3bd52"/>
                        <stop offset="100%" stop-color="#9d681b"/>
                    </linearGradient>
                    <clipPath id="kijkgat">
                        <rect x="0" y="0" :width="wWidth" :height="borderWidth" />
                        <rect x="0" y="0" :width="borderWidth" height="100%" />
                        <rect :x="( wWidth - borderWidth ) + 'px'" y="0" :width="borderWidth" height="100%" />
                        <rect x="0" :y="(wHeight - borderWidth) + 'px'" :width="wWidth" :height="borderWidth" />
                        <!-- <polygon :points="polyPath" /> -->
                    </clipPath>
                </defs>
                <rect x="0" y="0" width="100%" height="100%" fill="url(#gold-gradient)" clip-path="url(#kijkgat)" />
            </svg>

            <img v-if="$route.path !== '/'" src="/static/img/logo295jaar.png" class="logo-295" />

            <slot></slot>
        </div>

        <footer v-if="footerRoute">
            <div class="content-wrapper">
                <!-- <img class="footer-img" src="/static/img/logos/footer.png" /> -->
                <img class="footer-img" src="/static/img/logos/footer-big.png" />
                <p class="disclaimer">Voor het organiseren van Staatsloterij is aan Staatsloterij bv vergunning verleend door de Kansspelautoriteit, trekking Laan van Hoornwijck 55 te Rijswijk. <br />Deelname vanaf 18 jaar. Als je aan deze actie meedoet, dan accepteer je onze <a :href="avLink" target="_blank">actievoorwaarden</a>. <br class="hide-for-medium-up" /><a href="https://www.nederlandseloterij.nl/privacy" target="_blank">Privacy Statement</a> Nederlandse&nbsp;Loterij.</p>
            </div>
        </footer>
    </div>
</template>

<style lang="scss" scoped>
    $small: 720px;
    $medium: 1024px;
    $large: 1440px;

    $logo-295-small-height: 100px;
    $logo-295-medium-height: 150px;
    $logo-295-large-height: 200px;

    .logo-295 {
        position: relative;
        margin: 20px auto;
        height: $logo-295-small-height;
        width: auto;
        pointer-events: none;
        @media screen and ( min-width: $small ) {
            height: $logo-295-medium-height;
        }
        @media screen and ( min-width: $large ) {
            height: $logo-295-large-height;
        }
    }
</style>

<script>
    import BgElem from '@/app/components/Background';
    export default {
        components: {
            'bg-elem': BgElem
        },

        data()
        {
            return {
                wHeight: document.documentElement.getBoundingClientRect().height,
                wWidth: window.innerWidth,
                borderWidth: 15,

                footerRoute: true
            };
        },

        computed: {
            polyPath()
            {
                return '0,' + this.wHeight + ' ' + this.wWidth + ',' + this.wHeight + ' ' + this.wWidth + ',' + ( this.wHeight - 75 ) + ' 0,' + ( this.wHeight - 50 );
            },

            avLink()
            {
                if( this.$store.getters['entry/all'].nyxid )
                {
                    return '/static/pdf/actievoorwaarden-abo.pdf';
                }
                else
                {
                    return '/static/pdf/actievoorwaarden.pdf';
                }
            }
        },

        mounted()
        {
            window.addEventListener( 'resize', this.onResize );

            this.$nextTick( this.onResize );
        },

        methods: {
            onResize()
            {
                this.wHeight = document.documentElement.getBoundingClientRect().height;
                this.wWidth = window.innerWidth;
            }
        }
    };
</script>
